export default {
    userinfo: (state) => {
        return state.user
    },
    token: (state) => {
        return state.token
    }, 
    chaninAccInfo: (state) => {
        return state.chaninAccInfo
    }, 
    auth:(state) => {
        return state.auth
    }, 
}