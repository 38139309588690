import Message from './Message.vue'

const obj = {}

obj.install = function(vue) {
	// 1.Message已经被转成rander函数，需要创建组件构造器
	const MessageConstrutor = vue.extend(Message)  
	// 2.根据组件构造器，通过new关键字创建 组件实例
	const message = new MessageConstrutor();
	
	// 3.通过$mount挂载到某个根元素
	message.$mount(document.createElement('div'))
	
	// 4.将toast.$el添加到body中
	document.body.appendChild(message.$el)
	
	// 5.添加到vue的原型中
	vue.prototype.$msg = message
}

export default obj