<template>
    <div class="btn-box">
        <div class="btn cancel" @click="$emit('handleCancel')">{{cancelText}}</div>
        <div class="btn fill save" @click="$emit('handleSave')">{{saveText}}</div>
    </div>
</template>

<script>
export default {
    name:'BtnBox',
    data() {
        return {

        };
    },
    props:{
        cancelText:{
            default:'返回'
        },
        saveText:{
            default:'保存'
        },
    },
    created() {

    },
    mounted() {

    },
    methods: {

    }
};
</script>

<style scoped lang="less">
.btn-box {
    .cancel {
        margin-right: 20px;
    }
}
</style>
