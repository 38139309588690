<template>
    <div class="breadcrumb-box">
        <router-link 
            tag="div"
            class="item" 
            v-for="(item,index) in texts"  
            :to="item.path ? item.path : ''"
            :key="index">
            {{item.text}}
            <i>{{separator}}</i>
        </router-link>
    </div>
</template>

<script>
export default {
    name:'Breadcrumb',
    data() {
        return {

        };
    },
    props:{
        separator:{
            type:String,
            default:'>>'
        },
        texts:{
            type:Array,
            default:()=>[]
        },
        path:{
            type:String,
        }
    },
    created() {

    },
    mounted() {

    },
    methods: {

    }
};
</script>

<style scoped lang="less">
.breadcrumb-box {
    display: flex;
    padding-bottom: 14px;
    border-bottom: 1px solid #E0E4EF;
    margin-bottom: 30px;
    .item { 
        font-size: 17px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 21px;
        color: #3D405C;
        opacity: 1;
        cursor: pointer;
        &:last-child {
            cursor: inherit;
            i {
                display: none;
            }
        }
    }
}
</style>
