export class Storage {
	constructor(name){
		this.name = 'storage';
	}
	setItem(params) {
		let obj = {
			name:'',
			value:'',
			expires:"",
			startTime:new Date().getTime()//记录何时将值存入缓存，毫秒级
		}
		let options = {};
		//将obj和传进来的params合并
		Object.assign(options,obj,params);
		// 是否设置到期时间
		if (options.expires) {
			localStorage.setItem(options.name,JSON.stringify(options));
		}else {
			let type = Object.prototype.toString.call(options.value);
			//如果value是对象或者数组对象的类型，就先用JSON.stringify转一下，再存进去
			if(type == '[object Object]'){
				options.value = JSON.stringify(options.value);
			}
			if(type == '[object Array]'){
				options.value = JSON.stringify(options.value);
			}
			localStorage.setItem(options.name,options.value);
		}
	}
	getItem(name){
		let item = localStorage.getItem(name); 
		if(!item) return false;
		// 尝试转换，能转换就是对象，不能就是字符串
		try{
			item = JSON.parse(item);
		}catch(error){ 
			item = item;
		}
		// 是否设置过期时间
		if(item.startTime){
			let date = new Date().getTime();
			//何时将值取出减去刚存入的时间，与item.expires比较，如果大于就是过期了，如果小于或等于就还没过期
			if(date - item.startTime > item.expires){
			//缓存过期，清除缓存，返回false
				localStorage.removeItem(name);
				return false;
			}else{
			//缓存未过期，返回值
				return item.value;
			}
		}else{
		//如果没有设置失效时间，直接返回值
			return item;
		}
	}
	//移出缓存
	removeItem(name){
		localStorage.removeItem(name);
	}
	//移出全部缓存
	clear(){
		localStorage.clear();
	}
}
// 大写字母序列化
export function letterSort(arr) {
	arr.sort((a, b) => {
		let textA = a.toUpperCase();
		let textB = b.toUpperCase();
		return textA < textB ? -1 : textA > textB ? 1 : 0;
	})
	return arr
} 