
import { getautcompAuth, getauthuserAuth, getautcompInfoAuth } from '../../network/gas'
import store from 'store'

var roots = {
    auth : store.state.auth,
    // 企业认证
    qiyerenz() {
        return getautcompAuth().then(function(res) {
            res = res.data;
            if (res.code == 200 ) {
                if (res.data == 1) {
                    return {
                        type: true,
                        status: 1,
                        msg: '认证成功'
                    }
                } else {
                    return {
                        type: false,
                        status: 2,
                        msg: '认证失败'
                    }
                }
            } else {
                return {
                    status: 0,
                }
            }
        }).catch(function(err) {
            return {
                status: 0
            }
        })
    },
    // 管理员认证
    quanxianType() {
       return  getauthuserAuth().then(function(res) {
            res = res.data;
            // res.data = 1
            if (res.code == 200 ) {
                if (res.data == 1) {
                    return {
                        type: true,
                        status: 1,
                        msg: '认证成功'
                    }
                } else {
                    return {
                        type: false,
                        status: 2,
                        msg: '认证失败'
                    }
                }
            } else {
                return {
                    status: 0,
                }
            }
        }).catch(function(err) {
            return {
                status: 0,
            }
        })
    },
    // 基础资料
    jicuziliao() {
        return getautcompInfoAuth().then(function(res) {
            res = res.data;
            // res.data = 1
            if (res.code == 200 ) {
                if (res.data == 1) {
                    return {
                        type: true,
                        status: 1,
                        msg: '认证成功'
                    }
                } else {
                    return {
                        type: false,
                        status: 2,
                        msg: '认证失败'
                    }
                }
            } else {
                return {
                    status: 0,
                }
            }
        }).catch(function(err) {
            return {
                status: 0,
            }
        });
    },
    huizong(arr, fn, errFn) {
        var that = this;
        var arrs = arr == null ? [] : arr;
        var type = true;
        var text = ''
        for (var y = 0; y < arr.length; y++) {
            if (arrs[y] == 'also') {
                if (store.state.auth.company == 1 || store.state.auth.personal == 1) {
                    type = true;
                    text = '';
                } else if (store.state.auth.company != 1) {
                    type = false;
                    text = '您未进行企业认证，无法查看信息。'
                } else if (store.state.auth.personal != 1) {
                    type = false;
                    text = '您未进行实名认证，无法查看信息。'
                }  else {
                    type = false;
                    text = '网络错误'
                }
            };
            if (arrs[y] == 'company') {
                if (store.state.auth.company == 1) {
                    type = true;
                    text = '';
                } else {
                    type = false;
                    text = '您未进行企业认证，无法查看信息。'
                }
            };
            if (arrs[y] == 'base') {
                if (store.state.auth.base == 1) {
                    type = true;
                    text = '';
                } else {
                    type = false;
                    text = '您未填写基础资料，无法查看信息。'
                }
            }
        };
        if (type) {
            if (fn) {
                fn()
            };
        } else {
            if (errFn) {
                errFn(text)
            };
        }
    }
}

export default roots