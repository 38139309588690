<template>
    <div class="Message"> 
        <transition name="fade" >
            <div class="msg-wrap" v-if="visible" @click="wrapClick"></div>
        </transition>
        <transition name="bounce" >
            <div class="Message-box" v-if="visible">
                <div class="msg-main" 
                    :style="customStyle"
                    :class="{ 'loading':msgType === 'loading' }">
                    <div class="msg-head">
                        <div class="fx-box" >
                            <div class="icon" :class="iconType"></div>
                            <div v-if="(msgType !== 'loading')" class="title">{{ title ? title : '提示' }}</div>
                        </div>
                        <div v-if="close" @click="handleclose" class="close"></div>
                    </div>
                    <div class="msg-body">
                        <template v-if="typeof(content) === 'string'">
                            <p style="word-wrap:break-word">{{ content }}</p>
                        </template>
                        <template v-else>
                            <component :is="content" style="word-wrap:break-word"/>
                        </template>

                        <slot name="msg-body"></slot>
                    </div>
                    <div class="msg-foot">
                        <button
                            v-if="(msgType === 'confirm')"
                            class="btn cancel"
                            @click="handelCancelBtn">
                            {{ cancelBtnText ? cancelBtnText : '取消'}}
                        </button>
                        <button 
                            v-if="(msgType !== 'loading')"
                            class="btn ok" 
                            @click="handelOkBtn">
                            {{ okBtnText ? okBtnText : '确定' }}
                        </button>
                        <!-- <button class="btn confirm">{{confirmText}}</button> -->
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
// import { addClass } from 'utils/utils'
export default {
    name: "Message",
    data() {
        return {
            visible: false,
            title: "",
            content: "",
            cancelBtnText: "取消",
            okBtnText: "确定", 
            close: false,
            success: null,
            fail: null,
            loaded:null,
            icon: "info",
            msgType:'alert',// alert , confirm , loading 
            customStyle:{}
        };
    },
    computed: {
        iconType() {
            return this.icon ? this.icon : "info";
        }, 
    },
    watch:{
        visible(newVal) { 
            const body = document.getElementsByTagName('body')[0];
            newVal ? body.classList.add('popup-hidden') : body.classList.remove('popup-hidden') 
        }
    },
    created() {},
    mounted() {
        if (window.history && window.history.pushState) { 
            history.pushState(null, null, document.URL);
            window.addEventListener("popstate", this.handleclose);
        }
    },
    beforeDestroy() {
        window.removeEventListener("popstate", this.handleclose);
    },
    methods: {
        message(options) { 
            this.msgType = options.msgType;
            this.icon = options.icon;
            this.title = options.title;
            this.content = options.content;
            this.okBtnText = options.okBtnText;
            this.cancelBtnText = options.cancelBtnText;
            this.close = options.close;
            this.closeCb = options.closeCb;
            this.success = options.success;
            this.fail = options.fail;
            this.customStyle = options.customStyle; 
            this.visible = true; 
        },
        wrapClick() {
            if (this.msgType === 'loading') return
            this.handleclose();
        },
        handelOkBtn() {  
            this.success && this.success();
            this.visible = false; 
        },
        handelCancelBtn() { 
            this.fail && this.fail();
            this.visible = false; 
        },
        handelLoaded() { 
            this.handleclose();
        },
        handleclose() {
            this.closeCb && this.closeCb();
            this.visible = false; 
        }, 
    },
};
</script>

<style scoped lang="less">
.msg-wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0.5;
    background: #000;
    z-index: 9998;
    transition: all 0.5s;
}
.icon {
    width: 40px;
    height: 40px;
    margin-right: 20px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center;
    &.warning {
        background-image: url(./img/warning.png);
    }
    &.tips {
        background-image: url(./img/tips.png);
    }
    &.success {
        background-image: url(./img/success.png);
    }
    &.delect {
        background-image: url(./img/delect.png);
    }
    &.save {
        background-image: url(./img/save.png);
    }
    &.edit {
        background-image: url(./img/edit.png);
    }
    &.setting {
        background-image: url(./img/setting.png);
    }
    &.confirm {
        background-image: url(./img/confirm.png);
    }
    &.info {
        background-image: url(./img/info.png);
    }
    &.pay {
        background-image: url(./img/pay.png);
    }
    &.pay-fail {
        background-image: url(./img/pay-fail.png);
    }
    &.logout {
        background-image: url(./img/logout.png);
    }
    &.time {
        background-image: url(./img/time.png);
    }
    &.loading {
        width: 50px;
        height: 50px;
        background-image: url(./img/loading.png);
    }
    &.create {
        background-image: url(./img/create.png);
    }
    &.qiyong {
        background-image: url(./img/qiyong.png);
    }
    &.tingyong {
        background-image: url(./img/tingyong.png);
    }
}
.Message-box {
    position: fixed; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    .msg-main {
        width: 480px;
        min-height: 245px;
        background: #ffffff;
        border: 1px solid #ececec;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
        opacity: 1;
        border-radius: 10px;
        padding: 20px;
        padding-left: 19px;
        padding-right: 24px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;    
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        &.loading { 
            height: 165px;
            .msg-head {
                align-items: center;
                justify-content: center;
                .icon {
                    margin-right: 0;
                    animation:turn 2s infinite;
                }
                @keyframes turn{
                    0%{-webkit-transform:rotate(0deg);}
                    100%{-webkit-transform:rotate(360deg);}
                }
            }
            .msg-body {
                padding-left: 0;
                text-align: center;
            }
        }
    }
    .msg-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        .fx-box {
            align-items: center;
        }
        .title {
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: bold;
            line-height: 24px;
            color: #333333;
            opacity: 1;
        }
        .close {
            width: 15px;
            height: 15px;
            display: inline-block;
            background-repeat: no-repeat;
            background-position: center;
            background-image: url('./img/close.png');
            cursor: pointer;
        }
    }
    .msg-body {
        padding-left: 60px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #333333;
        opacity: 1;
        flex: 1;
    }
    .msg-foot {
        padding-left: 60px;
        position: initial;
        bottom: 40px;
        left: 0;
        width: 100%;
        padding-left: 79px;
        display: flex;
        justify-content: flex-end;
        margin-top: 40px;
        .btn {
            width: 82px;
            height: 34px;
            margin-right: 10px; 
            &.cancel {
                border: 1px solid #387EFD;
                color: #387EFD;
            }
            &:last-child {
                margin-right: 0;
                color: #fff;
                background: #387efd;
            }
            &:hover { 
                color: #fff;
                background: #306EDE!important;
            }
        }
    }
}
.bounce-enter-active {
  animation: bounce-in .3s;
}
.bounce-leave-active {
  animation: bounce-in .3s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active, 2.1.8 版本以下 */ {
    opacity: 0
}
</style>
