import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

// 引入element样式
import 'element-ui/lib/theme-chalk/index.css';
// import './element';
import ElementUI from 'element-ui'
Vue.use(ElementUI)


// markdown样式
import 'github-markdown-css'
// 代码高亮
import 'highlight.js/styles/github.css'

import 'assets/js/rem'
import roots from './assets/js/roots'
import 'wowjs/css/libs/animate.css'
import {
  Storage
} from 'utils/utils'

import VueCookie from 'vue-cookie'
import message from 'components/common/message'

Vue.use(message);
Vue.use(VueCookie);

// 创建事件总线
Vue.prototype.$bus = new Vue();
// 创建本地存储
Vue.prototype.$storage = new Storage();
Vue.prototype.$axios = axios;
Vue.prototype.$roots = roots;
// 富文本
// 注册面包屑
import Breadcrumb from 'components/common/breadcrumb/Breadcrumb'
Vue.component('Breadcrumb', Breadcrumb);

// 注册按钮
import BtnBox from 'components/content/btnBox/BtnBox'
Vue.component('BtnBox', BtnBox);



export const this_ = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')