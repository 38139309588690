import { http } from './request'

// 获取订单
export function getOrder(datas) {
   
    return http({
        url: '/web/getOrder',
        params: datas
    })
}

// 下载订单
export function getdownloadOrder(datas) {
  return http({
      url: '/web/downloadOrder',
      data: datas
  }) 
}

// 获取账单
export function getBill(datas) {
  return http({
      url: '/web/getBill',
      params: datas
  }) 
}

// 获取账单
export function postSetBillN(datas) {
  return http({
      method: 'post',
      url: '/web/setBillNote',
      data: datas
  }) 
}

// 获取订单
export function postSetOrderNote(datas) {
  return http({
      method: 'post',
      url: '/web/setOrderNote',
      data: datas
  })
}

// 获取订单产品
export function postSetOrderNotes(datas) {
  return http({
      method: 'get',
      url: '/web/Product/list',
      params: datas
  }) 
}

// 获取账单产品
export function postSetCeatNotes(datas) {
  return http({
      method: 'get',
      url: '/web/contract/list',
      params: datas
  }) 
}


// 创建表
export function postContractDeploy(datas) {
  return http({
      method: 'post',
      url: '/web/contract/createEvidenceStruct',
      data: datas
  }) 
}

// 获取产品结构
export function getContractStruct(datas) {
  return http({
      method: 'get',
      url: '/web/contract/struct',
      params: datas
  }) 
}

// 产品编辑 
export function postContractdataEdit(datas) {
  return http({
      method: 'post',
      url: '/web/contractdata/call',
      data: datas
  }) 
}
//

// 获取合约内容
export function postContractdataList(datas) {
  return http({
      method: 'get',
      url: '/web/contract/dataList',
      params: datas
  }) 
}

// 锁定
export function postContractSetflag(datas) {
  return http({
      method: 'post',
      url: '/web/contract/setflag',
      data: datas
  }) 
}  

// 企业认证
export function getautcompAuth() {
  return http({
      method: 'get',
      url: '/web/auth/compAuth',
  }) 
}

// 管理员认证
export function getauthuserAuth() {
  return http({
      method: 'get',
      url: '/web/auth/userAuth',
  }) 
}


// 基础资料填写
export function getautcompInfoAuth() {
  return http({
      method: 'get',
      url: '/web/auth/compInfoAuth',
  }) 
}

// 检查余额
export function getcheckBalance(data) {
  return http({
      method: 'get',
      url: '/web/checkBalance',
      params: data
  }) 
}
