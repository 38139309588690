import { 
    UPDATE_USER,
    LOGIN,
    LOGOUT, 
	SETCHANINACCINFO
} from './mutations-type'
import Vue from 'vue';
import cookie from 'vue-cookie'

export default { 
	[LOGIN](state,payload) {   
		state.user = payload?.user;
		state.token = payload?.token;
		state.user && Vue.prototype.$storage.setItem({name:'baas_user',value:state.user,expires:259200000});
		state.token && Vue.prototype.$storage.setItem({name:'baas_token',value:state.token,expires:259200000});
	},   
	[LOGOUT](state) {  
		state.user = "";
		state.token = "";
		Vue.prototype.$storage.removeItem('baas_token');
		Vue.prototype.$storage.removeItem('baas_user');
		Vue.prototype.$storage.removeItem('chanin_acc_info');
		Vue.prototype.$storage.removeItem('auth');
	},    
	[UPDATE_USER](state,payload) { 
		const {user} = state; 
		const {key,value} = payload; 
		user[key] = value;
		Vue.prototype.$storage.setItem({name:'baas_user',value:user,expires:259200000});
	},    
	[SETCHANINACCINFO](state,payload) {  
		state.chaninAccInfo = payload;
		// state.chaninAccInfo && Vue.prototype.$storage.setItem({name:'chanin_acc_info',value:state.chaninAccInfo,expires:259200000}); 
	},    
	// 登录成功保存权限信息
	setAuth(state,payload) { 
		state.auth = payload
		state.auth && Vue.prototype.$storage.setItem({name:'auth',value:state.auth});
	},
	// 更新权限信息
	updateAuth(state,payload) { 
		const {key,value} = payload
		state.auth[key] = value
		state.auth && Vue.prototype.$storage.setItem({name:'auth',value:state.auth});
	}
}