<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  created() {},
  methods: {
    reload() {
      this.isRouterAlive = false; //先关闭，
      this.$nextTick(() => {
        this.isRouterAlive = true; //再打开
      });
    },
  },
};
</script>

<style lang="less">
@import url("~assets/css/base.css");
@import url("~assets/css/normalize.css");
</style>
