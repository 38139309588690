import Vue from 'vue'
import VueRouter from 'vue-router'

// 解决vue-router@3.0版本以上，使用$router.push()跳转到相同路由下报错问题
// push  
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
// 解决vue-router@3.0版本以上，使用$router.replace()跳转到相同路由下报错问题
// replace
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
  // if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

// const files = require.context('@/pages', true, /router\.js$/);
// let arr = files.keys().map(key => {
//   const page = require('@/pages' + key.replace('.', ''));
//   return page.default;
// })
const routes = [
  // {
  //   path: '/',
  //   component: () => import('pages/layout/MainLayout'),
  //   children: [{
  //       path: '/',
  //       redirect: '/index',
  //     },
  //     {
  //       path: '/recharge',
  //       name: 'recharge',
  //       meta: {
  //         roles: ['also']
  //       },
  //       component: () => import('pages/home/Recharge'),
  //     },
  //     {
  //       path: '/payResult/:status',
  //       name: 'payResult',
  //       component: () => import('pages/home/PayResult')
  //     },
  //   ]
  // },
  // {
  //   path: '/login',
  //   name: 'login',
  //   component: () => import('pages/login/Login')
  // },
  // {
  //   path: '/registerSuccess',
  //   name: 'registerSuccess',
  //   component: () => import('pages/login/RegisterSuccess')
  // },
  {
    path: '/',
    redirect: '/index'
  },

  {
    path: '/index',
    name: 'baasIndex',
    // component: () => import('pages/baasIndex/Index'),
    beforeEnter: (to, from, next) => {
      console.log('进过收尾');
      window.location.href = '/show/index.html'
      return
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/pages/home/Home'),
    redirect: '/index',
    children: [{
        path: '/appScenarios',
        name: 'appScenarios',
        component: () => import('@/pages/appScenarios/AppScenarios.vue')
      },
      {
        path: '/verification',
        name: 'verification',
        component: () => import('@/pages/verification/Verification.vue')
      },
      {
        path: '/helpCenter',
        name: 'helpCenter',
        component: () => import('@/pages/helpCenter/HelpCenter.vue')
      },
      {
        path: '/document',
        name: 'document',
        component: () => import('@/pages/document/Document.vue'),
        children: [{
            path: 'description',
            name: 'description',
            component: () => import('../pages/document/children/description/Description.vue')
          },
          {
            path: 'advantage',
            name: 'advantage',
            component: () => import('../pages/document/children/description/Advantage.vue')
          },
          {
            path: 'term',
            name: 'term',
            component: () => import('../pages/document/children/description/Term.vue')
          },
          {
            path: 'scene',
            name: 'scene',
            component: () => import('../pages/document/children/description/Scene.vue')
          },
          {
            path: 'releaseNotes',
            name: 'releaseNotes',
            component: () => import('../pages/document/children/description/ReleaseNotes.vue')
          },

          // 操作指南
          {
            path: 'appCenter',
            name: 'appCenter',
            component: () => import('../pages/document/children/instructions/AppCenter.vue')
          },
          {
            path: 'saveCenter',
            name: 'saveCenter',
            component: () => import('../pages/document/children/instructions/SaveCenter.vue')
          },
          {
            path: 'resourceCenter',
            name: 'resourceCenter',
            component: () => import('../pages/document/children/instructions/ResourceCenter.vue')
          },
          {
            path: 'loginAndRegister',
            name: 'loginAndRegister',
            component: () => import('../pages/document/children/instructions/LoginAndRegister.vue')
          },
          {
            path: 'idAuthentication',
            name: 'idAuthentication',
            component: () => import('../pages/document/children/instructions/IdAuthentication.vue')
          },

          // 开发指南
          {
            path: 'quickStart',
            name: 'quickStart',
            component: () => import('../pages/document/children/devOfGuidelines/QuickStart.vue')
          },
          {
            path: 'accessGuide',
            name: 'accessGuide',
            component: () => import('../pages/document/children/devOfGuidelines/AccessGuide.vue')
          },
          {
            path: 'apiConcept',
            name: 'apiConcept',
            component: () => import('../pages/document/children/devOfGuidelines/ApiConcept.vue')
          },
          {
            path: 'depositCertificate',
            name: 'depositCertificate',
            component: () => import('../pages/document/children/devOfGuidelines/DepositCertificate.vue')
          },
          {
            path: 'trace',
            name: 'trace',
            component: () => import('../pages/document/children/devOfGuidelines/Trace.vue')
          },
          // 常见问题
          {
            path: 'commonProblem',
            name: 'commonProblem',
            component: () => import('../pages/document/children/problem/CommonProblem.vue')
          },
        ]
      },
    ]
  },
  {
    path: '/control',
    name: 'control',
    component: () => import('@/pages/control/Control.vue')

  },
]
// routes[0].children.push(...arr);

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router