import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'
// import cookie from 'vue-cookie'
import { Storage } from 'utils/utils'
const strore = new Storage();  

Vue.use(Vuex)

let token = strore.getItem('baas_token');
let user = strore.getItem('baas_user');
let chaninAccInfo = strore.getItem('chanin_acc_info');
let auth = strore.getItem('auth');

const state = {
    user,
    token, 
    chaninAccInfo,
    auth
}

const store = new Vuex.Store({
    state,
    mutations,
    actions,
    getters
})

export default store