
import { http } from './request'

// 企业认证
export function getautcompAuth() {
  return http({
    method: 'get',
    url: '/web/auth/compAuth',
  })
}

// 管理员认证
export function getauthuserAuth() {
  return http({
    method: 'get',
    url: '/web/auth/userAuth',
  })
}


// 基础资料填写
export function getautcompInfoAuth() {
  return http({
    method: 'get',
    url: '/web/auth/compInfoAuth',
  })
}


// 获取账户信息
export function getChaninAccount() {
  return http({
    url: '/web/chainaccount/info',
    method: 'post',
  })
}