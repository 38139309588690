import {   
} from './mutations-type' 
import { 
    getautcompAuth, // 企业
    getauthuserAuth, // 管理员
    getautcompInfoAuth  // 基础权限
} from 'network/auth'
export default {   
    getAuth(context,payload) { 
		return new Promise(async (resolve,reject)=>{
            let res,value;
            const {key} = payload;
            switch (key) {
                case 'base':
                    res = await getautcompInfoAuth(); 
                break
                case 'personal':
                    res = await getauthuserAuth(); 
                break
                case 'company':
                    res = await getautcompAuth();
                break
            }   
            const {data} = res
            if (data.code == 200) {
                value = data.data;
                context.commit('updateAuth',{key,value})
                resolve('成功！') 
            }else {
                reject('失败！')
            }
        })
    }, 
}